import { configureStore } from "@reduxjs/toolkit";
import { sidebarSlice } from "./reducers/sidebar";
import { usersApi } from "./api/users";
import { usersSlice } from "./reducers/userSlice";
import { candidateStatusApi } from "./api/candidateStatus";

const store = configureStore({
  reducer: {
    sidebar: sidebarSlice.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [candidateStatusApi.reducerPath]: candidateStatusApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(usersApi.middleware,candidateStatusApi.middleware),
});

export default store;
