import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config";
import { getTokenFromCookies } from "./users";

export const candidateStatusApi = createApi({
  reducerPath: "candidateStatusApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: async (headers) => {
      const token = await getTokenFromCookies();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getCandidateStatus: builder.mutation({
      query: ({ applicationStatus }) => ({
        url: `/api/get_candidate_status`,
        method: "POST",
        body: { applicationStatus },
      }),
      transformResponse: (response, meta, arg) => {
        return response?.linkedCandidateStatus?.linkedCandidateStatus
      },
    }),
  }),
});

export const { useGetCandidateStatusMutation } = candidateStatusApi;
